window.require = require
window.jQuery = window.$ = require('jquery')


var tri = require('tripartite')
var tripartite = tri

recaptchaSetup = require('webhandle-emailer/client-js/make-form-recaptcha')

var SwipeListener = require('swipe-listener')

var Shiner = require('shiner/shiner-no-jquery')($)
window.Shiner = Shiner

var total = $('.shiner-show .slide').length

if (total > 1) {

	$('.shiner-show .slide').addClass('invisible').eq(0).removeClass('invisible').addClass('visible')
	$('.shiner-show').addClass('transitions-on')

	$('.shiner-show .dots').html('')
	for(var i = 0; i < total; i++) {
		$('.shiner-show .dots').append('<div class="dot">&nbsp;</div>')
	}

	$('.shiner-show .dots .dot').eq(0).addClass('current')

	var options = {}
	if (window.shinerDelay) {
		options.delay = window.shinerDelay
	}

	var shine = $('.shiner-show .slide').shiner(options)
	window.shine = shine


	$('.shiner-show .advance').on('click', function (evt) {
		shine.next()
	})
	$('.shiner-show .previous').on('click', function (evt) {
		shine.previous()
	})

	shine.onVisible = function (slide, ind) {
		var $placeOf = $('.shiner-show .place-of')
		if ($placeOf.length > 0) {
			$placeOf.html((ind + 1) + ' of ' + total)
		}

		var $theShow = $(slide).closest('.shiner-show')
		$theShow.find('.dot').removeClass('current').eq(ind).addClass('current')
	}


	var container = $('.shiner-show').get(0)
	try {
		window.shinerSwipeListener = SwipeListener(container)
		container.addEventListener('swipe', function (e) {
			var directions = e.detail.directions
			var x = e.detail.x
			var y = e.detail.y

			if (directions.left) {
				shine.next()
			}
			if (directions.right) {
				shine.previous()
			}
		})
	}
	catch(e) {
		
	}

	$('.shiner-show .dots .dot').on('click', function(evt) {
		var dotPos = $('.shiner-show .dots .dot').index(this)
		shine.showSlide(dotPos)
	})


}
else {
	$('.shiner-show .slide').removeClass('invisible').addClass('visible')
	$('.shiner-show').addClass('transitions-on')
}




$(function () {
	$('header .tribar, header .close').on('click', function (evt) {
		evt.preventDefault()
		$('header nav').toggleClass('open')
		$('body').toggleClass('locked');
	})
	$('header .menu li').removeClass('current')
	$('header .menu a').each(function () {
		if ($(this).attr('href') == window.location.pathname) {
			$(this).closest('li').addClass('current')
		}
	})

	$('.expando .opener').on('click', function (evt) {
		evt.preventDefault()
		let $this = $(this)
		let $expando = $this.closest('.expando')
		let $parentExpando = $expando.parent().closest('.expando')
		if ($('html').hasClass('editing-page')) {
			$expando.removeClass('open')
			return
		}
		let open = $expando.hasClass('open')
		$expando.toggleClass('open')
		let $view = $expando.children('.view')

		let $content = $expando.find('.content')
		let heightIncrement = (parseInt($content.height()) + 50)
		if (!open) {
			$view.css('max-height', heightIncrement + 'px')
			if ($parentExpando.length > 0) {
				let $parentView = $parentExpando.children('.view')
				let parentHeight = parseInt($parentView.css('max-height')) + heightIncrement
				$parentView.css('max-height', parentHeight)
			}
		}
		else {
			$view.css('max-height', 0)
			if ($parentExpando.length > 0) {
				let $parentView = $parentExpando.children('.view')
				let parentHeight = parseInt($parentView.css('max-height')) - heightIncrement
				$parentView.css('max-height', parentHeight)
			}
		}
	})
	$('.landing-box').on('click', function (evt) {
		let $this = $(this)
		let link = $this.attr('data-link')
		if (link && !$('html').hasClass('editing-page')) {
			evt.preventDefault()
			window.location = link
		}
	})

	function setCurrentCalendarDateHeader() {
		var $cur = $(findCurrentCalendarDateHeader()).closest('.month-marker-holder');
		
		// remove active styling	
		$('.months .month').removeClass('active');
		$('.years .year').removeClass('active');
		

		var month = $cur.attr('data-long-month');
		var year = $cur.attr('data-year');
		var $label = $('.months .month[data-long-month=' + month + ']');
		var $yearLabel = $('.years .year[data-year=' + year + ']');
		$label.addClass('active');
		$yearLabel.addClass('active');
		if ($yearLabel.hasClass('future')) {
			$('.months .month').addClass('next-year');
		}
		else {
			$('.months .month').removeClass('next-year');
		}
	}

	function findCurrentCalendarDateHeader() {
		var $notStuck = $('.month-marker').not('.stuck');
		if ($notStuck.length > 0) {
			var firstNotStuck = $notStuck[0];
			var relativePosition = firstNotStuck.offsetTop - $('.all-events')[0].scrollTop;

			if (relativePosition <= ($('.all-events').height() / 2)) {
				return firstNotStuck;
			}

			return $('.month-marker.stuck').last()[0];
		}
	}

	function scrollToMonth(monthName, year) {

		var off;
		if (year) {
			off = $('.month-marker-holder[data-long-month=' + monthName + ']')
			.filter( '[data-year=' + year + ']')[0].offsetTop;
		}
		else {
			off = $('.month-marker-holder[data-long-month=' + monthName + ']')[0].offsetTop;
		}

		var scrollSelector = '.all-events';
		if ($('.all-events').css('overflow') != 'scroll' && $('.all-events').css('overflow-y') != 'scroll') {
			scrollSelector = 'html,body';
			off = off + 400;
		}
		else {
			// off -= $('.all-events')[0].offsetTop
			off -= 60
		}

		$(scrollSelector).animate(
			{
				scrollTop: off
			},
			500,
			function () {
				$('.month-short-name').removeClass('active');
				$('.month-short-name[data-long-month=' + monthName + ']').addClass('active');
			}
		);

	}
	$('.all-events').on('scroll', function (evt) {
		var scrolled = (this).scrollTop;
		var halfWayFound = false;
		$(this).find('.month-marker-holder').each(function () {
			if (this.offsetTop <= scrolled) {
				$(this).find('.month-marker').addClass('stuck').css('margin-top', '');
			}
			else if (this.offsetTop <= (scrolled + this.scrollHeight)) {
				var diff = (scrolled + this.scrollHeight) - this.offsetTop;
				$('.month-marker.stuck').css('margin-top', '-' + diff + 'px');
				$(this).find('.month-marker').removeClass('stuck').css('margin-top', '');
				halfWayFound = true;
			}
			else if (this.offsetTop + this.scrollHeight <= scrolled) {
				// This keeps a header that's just coming into view from having its 
				// "stuck" class removed.
			}
			else {
				$(this).find('.month-marker').removeClass('stuck').css('margin-top', '');
			}
		});
		if (!halfWayFound) {
			// If no half way header is found then we want to make sure all of the stuck
			// headers are at the top and not scrolled up.
			$('.month-marker.stuck').css('margin-top', '');
		}
		setCurrentCalendarDateHeader();
	});

	$('.page-calendar .months > div').on('click', function (evt) {
		var cur = this;
		evt.preventDefault();
		var extra = $(this).hasClass('next-year') ? 1 : 0;
		scrollToMonth($(this).attr('data-long-month'), extra + parseInt($(this).attr('data-year')));
	});

	$('.page-calendar .months > div.active').each(function () {
		scrollToMonth($(this).attr('data-long-month'));
	});

	$('.years .year').on('click', function (evt) {
		evt.preventDefault();
		scrollToMonth("January", $(this).attr('data-year'));
	});
	
	if($('.all-events').length > 0) {
		let events = $('.all-events .event')
		let now = new Date().getTime()
		for(let i = 0; i < events.length; i++) {
			let mtime = parseInt($(events[i]).attr('data-mtime'))
			if(mtime > now) {
				console.log(mtime)
				var scrollSelector = '.all-events'
				var off
				off = events[i].offsetTop;
				if ($('.all-events').css('overflow') != 'scroll' && $('.all-events').css('overflow-y') != 'scroll') {
					scrollSelector = 'html,body';
					off = off + 400;
				}
				else {
					// off -= $('.all-events')[0].offsetTop
					off -= 60
				}
				$(scrollSelector).animate(
					{
						scrollTop: off
					},
					500,
					function () {
						// $('.month-short-name').removeClass('active');
						// $('.month-short-name[data-long-month=' + monthName + ']').addClass('active');
					}
				);
				break
			}
		}
	}
	
	$('.expando.open').removeClass('open')
	
	if(window.location.hash) {
		let target = window.location.hash.substring(1)
		$('a[id="' + target + '"], a[name="' + target + '"]').each(function() {
			let opener = $(this).closest('.expando').find('.opener')
			
			setTimeout(function() {
				opener.trigger('click')
			}, 5)
		})

		
	}
	
	$('.staff-profile-cells .staff-profile').on('click', function(evt) {
		if($(this).hasClass('show')) {
			// close the profile
			$('.staff-profile-cells .staff-profile').removeClass('show')
			$('.staff-profile-cells .staff-profile .details-height').css('min-height', 0)
		}
		else {
			// close the others and open the new
			$('.staff-profile-cells .staff-profile').removeClass('show')
			$('.staff-profile-cells .staff-profile .details-height').css('min-height', 0)
			
			// open the new one
			$(this).addClass('show')
			let height = $(this).find('.details').height()
			$(this).find('.details-height').css('min-height', height)
		}

	})
	
	if(window.recaptchaId && $('form.google-recaptcha-form').length > 0) {
		recaptchaSetup(window.recaptchaId)
	}

})